<script setup lang="ts">
import '~/assets/scss/tlgrm.scss'
import Application from "~/enums/Application";

const {isSummaryLoaded} = storeToRefs(useSummaryStore());

useHead(appHead(Application.MiniApp));
</script>

<template>
  <div>
    <div class="container px-2 py-2">
      <TelegramMiniTracking
        v-if="isSummaryLoaded"
        class="mb-3"
        container-results-class="position-sticky top-2 z-1"
        container-multiple-results-class="border rounded p-2 bg-body"
      />
      <div class="px-2">
        <slot />
      </div>
    </div>
    <AppToaster />
  </div>
</template>